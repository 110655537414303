
import { computed, defineComponent, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';

import SettingsModule from '@/store/settings';
import QuizModule from '@/store/settings/quiz';
import QuizEntityModule from '@/store/settings/quiz/entity';

import { GuiLoader } from '@library/gigant_ui';
import QuizForm from './_form.vue';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import AppTable from '@/components/ui/table/Table.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import FormSelect from '@/components/form/Select/index.vue';
import StatusActive from '@/components/table-items/StatusActive.vue';
import AppHeaderBtnAdd from '@/layouts/partials/AppHeaderBtnAdd.vue';
import useIntersectionObserver from '@/composition/app/useIntersectionObserver';

export default defineComponent({
  components: {
    TabsNav,
    AppHeaderBtnAdd,
    AppRow,
    AppCol,
    AppTable,
    StatusActive,
    FormSelect,
    GuiLoader,
    QuizForm,
  },
  setup() {
    const header = ref<InstanceType<typeof AppCol> | null>(null);
    const table = ref<InstanceType<typeof AppTable> | null>(null);
    const activeRow = ref<HTMLElement | null>(null);
    const isHeaderInView = ref(true);

    const { stop: stopIntersectionObserve } = useIntersectionObserver(header, ([{ isIntersecting }]) => {
      isHeaderInView.value = isIntersecting;
    });

    const tabsNav = computed(() => SettingsModule.tabsNav);
    const currentTab = computed(() => SettingsModule.allTabs.quizzes.id);
    const filterSettings = computed(() => QuizModule.filter.filterSettings.filterModel);
    const settings = computed(() => QuizModule.pageSettings);
    const isLoadingList = computed(() => QuizModule.isLoading);
    const isLoadingEntity = computed(() => QuizEntityModule.isLoading);
    const isInitiatedTable = computed(() => Object.keys(settings.value.table).length);
    const isAdd = computed(() => SettingsModule.isAdd);
    const isEdit = computed(() => SettingsModule.isEdit);

    watch(
      () => activeRow.value,
      (newActiveElement: HTMLElement | null, previousActiveElement: HTMLElement | null) => {
        if (newActiveElement) {
          newActiveElement.classList.add('app-table-body-row--selected');
        }

        if (previousActiveElement) {
          previousActiveElement.classList.remove('app-table-body-row--selected');
        }
      }
    );

    function selectAmount(value: string) {
      QuizModule.updatePageAmountItems(value);
    }

    function sort(header: { id: string; sort: { value: string } }) {
      QuizModule.sort({ field: header.id, sort: header.sort.value || '' });
    }

    async function handleFilterSelect(params: Parameters<typeof QuizModule.changeFilterSelect>[0]) {
      await QuizModule.changeFilterSelect(params);
    }

    function toAdd(): void {
      QuizModule.updateIsAdd(true);
      activeRow.value = null;
    }

    function toEdit(id: string): void {
      if (table.value) {
        activeRow.value = table.value.$el.querySelector(`.app-table-body-row--id-${id}`);
      }

      QuizModule.updateIsEdit({ isEdit: true, id: id.toString() });
    }

    function handleSaved(quizId: string): void {
      activeRow.value = null;

      if (table.value) {
        activeRow.value = table.value.$el.querySelector(`.app-table-body-row--id-${quizId}`);
      }
    }

    onMounted(async () => {
      SettingsModule.initTabsItems();
      QuizModule.init();

      await nextTick(() => {
        SettingsModule.SET_IS_ADD(false);
        SettingsModule.SET_IS_EDIT(false);
      });
    });

    onBeforeUnmount(stopIntersectionObserve);

    return {
      header,
      isHeaderInView,
      table,
      activeRow,
      tabsNav,
      currentTab,
      filterSettings,
      settings,
      isInitiatedTable,
      isLoadingList,
      isLoadingEntity,
      isAdd,
      isEdit,
      selectAmount,
      sort,
      handleFilterSelect,
      toAdd,
      toEdit,
      handleSaved,
    };
  },
});

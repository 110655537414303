import { Module, Action, getModule, Mutation } from 'vuex-module-decorators';
import store from '@/store';
import EntityBaseModule from '@/store/entity';

import QuizModule from '@/store/settings/quiz';
import { deepEqual } from '@/lib/util/helpers';
import { strings } from '@/lib/stringConst';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import {
  getQuizById,
  createQuiz,
  createQuestion,
  createAnswer,
  updateQuiz,
  deleteAnswer,
  deleteQuestion,
  updateQuestion,
  updateAnswer,
} from '@/api/quiz';
import { CatchFormResponse, FormData } from '@/interfaces/shared';
import { AnswerModel, QuestionModel, QuizEntity, QuizModel } from '@/interfaces/models/quiz.interface';

export const MODULE_NAME = 'quizEntity';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class QuizEntityModule extends EntityBaseModule {
  isLoading = false;
  isSaving = false;
  model: Partial<QuizModel> = {};

  errors = {
    client: false,
    competence: false,
    questions: new Map<QuestionModel, Record<string, string | boolean>>(),
    answers: new Map<AnswerModel, Record<string, string | boolean>>(),
  };

  questionDeleteQueue: Set<string> = new Set();
  answerDeleteQueue: Map<string, Set<string>> = new Map();

  initialPreparedQuiz: FormData = {};
  initialPreparedQuestions = new Set<FormData>();
  initialPreparedAnswers = new Set<FormData>();

  constructor(module: QuizEntityModule) {
    super(module);
  }

  @Mutation
  SET_IS_SAVING(value: boolean) {
    this.isSaving = value;
  }

  @Mutation
  RESET_ERRORS() {
    this.errors.client = false;
    this.errors.competence = false;
    this.errors.questions.clear();
    this.errors.answers.clear();
  }

  @Mutation
  ADD_QUESTION_TO_DELETE_QUEUE(id: string) {
    this.questionDeleteQueue.add(id);
  }

  @Mutation
  ADD_ANSWER_TO_DELETE_QUEUE(params: { id: string; parentId: string }) {
    let queue = this.answerDeleteQueue.get(params.parentId);

    if (!queue) {
      queue = new Set();
      this.answerDeleteQueue.set(params.parentId, queue);
    }

    queue.add(params.id);
  }

  @Mutation
  REDUCE_ANSWER_DELETE_QUEUE() {
    const answerDeleteParents = Array.from(this.answerDeleteQueue.keys());
    const duplicateParents = answerDeleteParents.filter((parentQuestion) => this.questionDeleteQueue.has(parentQuestion));

    for (const duplicateParent of duplicateParents) {
      this.answerDeleteQueue.delete(duplicateParent);
    }
  }

  @Mutation
  RESET_DELETE_QUEUES() {
    this.questionDeleteQueue.clear();
    this.answerDeleteQueue.clear();
  }

  static setEntityError<T extends QuestionModel | AnswerModel>(params: {
    collection: Map<T, Record<string, string | boolean>>;
    entity: T;
    key: string;
    value: string | boolean;
  }) {
    let entityErrors = params.collection.get(params.entity);
    if (!entityErrors) {
      entityErrors = {};
      params.collection.set(params.entity, entityErrors);
    }

    Object.assign(entityErrors, { [params.key]: params.value });
  }

  @Mutation
  SET_ERROR(params: { key: string; value: boolean | string; question?: QuestionModel; answer?: AnswerModel }) {
    if (params.question) {
      QuizEntityModule.setEntityError({
        collection: this.errors.questions,
        entity: params.question,
        key: params.key,
        value: params.value,
      });

      return;
    }

    if (params.answer) {
      QuizEntityModule.setEntityError({
        collection: this.errors.answers,
        entity: params.answer,
        key: params.key,
        value: params.value,
      });

      return;
    }

    this.errors[params.key as 'client' | 'competence'] = !!params.value;
  }

  @Mutation
  SET_INITIAL_PREPARED_QUIZ(value: FormData) {
    this.initialPreparedQuiz = value;
  }

  @Mutation
  SET_INITIAL_PREPARED_QUESTIONS(value: Set<FormData>) {
    this.initialPreparedQuestions = value;
  }

  @Mutation
  SET_INITIAL_PREPARED_ANSWERS(value: Set<FormData>) {
    this.initialPreparedAnswers = value;
  }

  @Action({ rawError: true })
  async setInfo(info: QuizEntity) {
    const sortByIndex = <T extends { index: number }>(a: T, b: T) => a.index - b.index;

    info.questions?.sort(sortByIndex);
    info.questions?.forEach((question) => question.answers?.sort(sortByIndex));

    const threshold = Math.min(info.questions?.length || 1, info.threshold);

    this.context.commit('SET_MODEL_VALUE', { key: 'id', value: info.id });
    this.context.commit('SET_MODEL_VALUE', { key: 'threshold', value: threshold });
    this.context.commit('SET_MODEL_VALUE', { key: 'isActive', value: info.isActive });
    this.context.commit('SET_MODEL_VALUE', { key: 'questions', value: info.questions });

    const client = info.client;
    if (client) {
      this.context.commit('SET_MODEL_VALUE', { key: 'client', value: { id: client.id, value: client.name } });
    }

    const competence = info.competence;
    if (competence) {
      this.context.commit('SET_MODEL_VALUE', { key: 'competence', value: { id: competence.id, value: competence.name } });
    }

    await this.prepareInitialModels();
    this.updateIsLoading(false);
  }

  @Action({ rawError: true })
  resetModel() {
    this.context.commit('RESET_ERRORS');
    this.context.commit('RESET_DELETE_QUEUES');

    this.context.commit('SET_MODEL_VALUE', { key: 'id', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'client', value: { id: '', value: '' } });
    this.context.commit('SET_MODEL_VALUE', { key: 'competence', value: { id: '', value: '' } });
    this.context.commit('SET_MODEL_VALUE', { key: 'questions', value: [] });
    this.context.commit('SET_MODEL_VALUE', { key: 'threshold', value: 1 });
    this.context.commit('SET_MODEL_VALUE', { key: 'isActive', value: true });

    this.updateIsLoading(false);
  }

  @Action({ rawError: true })
  addNewQuestion() {
    const questions = this.model.questions ?? [];
    const newQuestionIndex = questions.length || 0;
    const newQuestion = { id: '', text: '', answers: [], index: newQuestionIndex };

    this.addNewAnswer(newQuestion);
    this.addNewAnswer(newQuestion);
    questions.push(newQuestion);

    if (!this.model.questions) {
      this.context.commit('SET_MODEL_VALUE', { key: 'questions', value: questions });
    }
  }

  @Action({ rawError: true })
  addNewAnswer(question: QuestionModel) {
    const answers = question.answers ?? [];
    const generateTemporaryId = (): string => (Math.ceil(Math.random() * 10000) + 1000).toString();
    const isExistsTemporaryId = (id: string) => answers.find((answer: AnswerModel) => answer.temporaryId === id);

    let temporaryId = generateTemporaryId();
    while (isExistsTemporaryId(temporaryId)) {
      temporaryId = generateTemporaryId();
    }

    const newAnswerIndex = answers.length || 0;
    const newAnswer = { temporaryId, id: '', text: '', isCorrectAnswer: false, index: newAnswerIndex };

    answers.push(newAnswer);
  }

  @Action({ rawError: true })
  removeQuestion(questionForDelete: QuestionModel) {
    this.model.questions = this.model.questions?.filter((question) => question !== questionForDelete);

    const threshold = this.model.threshold || 1;
    const maxPossibleThreshold = this.model.questions?.length || 1;

    if (threshold > maxPossibleThreshold) {
      this.model.threshold = maxPossibleThreshold;
    }

    if (questionForDelete.id) {
      this.context.commit('ADD_QUESTION_TO_DELETE_QUEUE', questionForDelete.id.toString());
    }
  }

  @Action({ rawError: true })
  removeAnswer(answerForDelete: AnswerModel) {
    const question = this.model.questions?.find((question) => question.answers?.includes(answerForDelete));

    if (question) {
      question.answers = question.answers.filter((answer) => answer !== answerForDelete);
    }

    if (answerForDelete.id) {
      this.context.commit('ADD_ANSWER_TO_DELETE_QUEUE', {
        parentId: question?.id.toString(),
        id: answerForDelete.id.toString(),
      });
    }
  }

  @Action({ rawError: true })
  replaceQuestion(props: { oldIndex: number; newIndex: number }) {
    if (!this.model.questions) {
      return;
    }

    const [questionForReplace] = this.model.questions.splice(props.oldIndex, 1);

    this.model.questions.splice(props.newIndex, 0, questionForReplace);
    this.model.questions.forEach((question, index) => (question.index = index));
  }

  @Action({ rawError: true })
  replaceAnswer(props: { question: QuestionModel; oldIndex: number; newIndex: number }) {
    const answers = props.question.answers;
    const [answerForReplace] = answers.splice(props.oldIndex, 1);

    answers.splice(props.newIndex, 0, answerForReplace);
    answers.forEach((answer, index) => (answer.index = index));
  }

  @Action({ rawError: true })
  validateQuiz(): boolean {
    this.RESET_ERRORS();

    const isEmptyClient = !this.model.client?.id?.toString().length;
    const isEmptyCompetence = !this.model.competence?.id?.toString().length;

    this.context.commit('SET_ERROR', { key: 'client', value: isEmptyClient });
    this.context.commit('SET_ERROR', { key: 'competence', value: isEmptyCompetence });
    let isValid = !isEmptyClient && !isEmptyCompetence;

    const getEntityTextError = (entity: QuestionModel | AnswerModel, limit = 1024): string | false => {
      let entityTextError = !entity.text.trim().length && strings.errorRequired;
      if (!entityTextError) {
        entityTextError = entity.text.trim().length > limit && strings.errorMaxLength;
      }

      return entityTextError;
    };

    for (const question of this.model.questions ?? []) {
      const questionTextError = getEntityTextError(question);
      this.context.commit('SET_ERROR', { question, key: 'text', value: questionTextError });
      isValid &&= !questionTextError;

      let isEveryAnswersIsNotCorrect = true;

      for (const answer of question.answers ?? []) {
        const answerTextError = getEntityTextError(answer);
        this.context.commit('SET_ERROR', { answer, key: 'text', value: answerTextError });
        isValid &&= !answerTextError;

        if (isEveryAnswersIsNotCorrect) {
          isEveryAnswersIsNotCorrect = !answer.isCorrectAnswer;
        }
      }

      this.context.commit('SET_ERROR', { question, key: 'isCorrectAnswer', value: isEveryAnswersIsNotCorrect });
      isValid &&= !isEveryAnswersIsNotCorrect;
    }

    return isValid;
  }

  @Action({ rawError: true })
  prepareQuizData(): FormData<string> {
    return {
      id: this.model.id?.toString() ?? '',
      competence: this.model.competence?.id?.toString() ?? '',
      client: this.model.client?.id?.toString() ?? '',
      isActive: this.model.isActive ? '1' : '0',
      threshold: this.model.threshold?.toString() || '1',
    };
  }

  @Action({ rawError: true })
  prepareQuestionsData(): Map<FormData<string>, QuestionModel> {
    const entries: [FormData<string>, QuestionModel][] =
      this.model.questions?.map((question) => [
        {
          id: question.id?.toString() ?? '',
          index: question.index.toString() ?? '',
          text: question.text.trim(),
        },
        question,
      ]) ?? [];

    return new Map(entries);
  }

  @Action({ rawError: true })
  prepareAnswersData(question: QuestionModel): Map<FormData, AnswerModel> {
    const entries: [FormData, AnswerModel][] =
      question.answers?.map((answer) => [
        {
          id: answer.id?.toString() ?? '',
          index: answer.index?.toString() ?? '',
          text: answer.text.trim(),
          isCorrectAnswer: answer.isCorrectAnswer ? '1' : '0',
        },
        answer,
      ]) ?? [];

    return new Map(entries);
  }

  @Action({ rawError: true })
  async getQuizById(quizId: string) {
    try {
      this.resetModel();
      this.SET_IS_LOADING(true);

      const quiz = await getQuizById(quizId);
      this.setInfo(quiz);
    } catch (error) {
      ResponseHandlerModule.showNotify({
        message: (error as CatchFormResponse<string, string>)?.response?.data?.errors?.fields ?? strings.UNKNOWN_ERROR,
        type: 'fail',
      });
    } finally {
      this.SET_IS_LOADING(false);
    }
  }

  @Action({ rawError: true })
  async save() {
    try {
      this.SET_IS_SAVING(true);

      const isValidQuiz = await this.validateQuiz();
      if (!isValidQuiz) {
        ResponseHandlerModule.showNotify({
          message: 'Проверьте, правильно ли заполнены все поля, и попробуйте отправить форму снова',
          type: 'fail',
        });

        return;
      }

      await this.handleDeleteQueues();
      const savedQuiz = await this.saveQuiz();
      await this.saveQuestions(savedQuiz.id);
      await this.prepareInitialModels();

      ResponseHandlerModule.showNotify({
        message: 'Тест сохранён',
        type: 'ok',
      });

      this.context.commit('RESET_DELETE_QUEUES');

      await QuizModule.getList();

      return savedQuiz.id;
    } catch (error) {
      const errors = (error as CatchFormResponse<unknown, Record<string, string>>)?.response?.data?.errors;
      const errorMessages = errors?.fields instanceof Object && Object.values(errors.fields).join('. ');

      ResponseHandlerModule.showNotify({
        message: errorMessages || strings.UNKNOWN_ERROR,
        type: 'fail',
      });
    } finally {
      this.SET_IS_SAVING(false);
    }
  }

  @Action({ rawError: true })
  async prepareInitialModels(): Promise<void> {
    this.SET_INITIAL_PREPARED_QUIZ(await this.prepareQuizData());

    const preparedQuestions = Array.from((await this.prepareQuestionsData()).keys());
    this.SET_INITIAL_PREPARED_QUESTIONS(new Set(preparedQuestions));

    const preparedAnswers = new Set<FormData>();
    for (const question of this.model.questions ?? []) {
      const answersData = await this.prepareAnswersData(question);
      Array.from(answersData.keys()).forEach((preparedAnswer) => {
        preparedAnswers.add(preparedAnswer);
      });
    }

    this.SET_INITIAL_PREPARED_ANSWERS(preparedAnswers);
  }

  @Action({ rawError: true })
  private async handleDeleteQueues() {
    this.context.commit('REDUCE_ANSWER_DELETE_QUEUE');

    Array.from(this.answerDeleteQueue.entries()).forEach(([questionId, answersForDelete]) => {
      answersForDelete.forEach(async (answerId) => {
        await deleteAnswer(questionId, answerId);
      });
    });

    const quizId = this.model.id;
    if (!quizId) {
      return;
    }

    Array.from(this.questionDeleteQueue.values()).forEach(async (questionForDelete) => {
      await deleteQuestion(quizId, questionForDelete);
    });
  }

  @Action({ rawError: true })
  private async saveQuiz(): Promise<QuizEntity> {
    const quizData = await this.prepareQuizData();
    let savedQuiz: QuizEntity = {
      id: quizData.id,
      client: {
        id: this.model.client?.id?.toString() ?? '',
        name: this.model.client?.value?.toString() ?? '',
      },
      competence: {
        id: this.model.competence?.id?.toString() ?? '',
        name: this.model.competence?.value?.toString() ?? '',
      },
      questions: this.model.questions ?? [],
      threshold: this.model.threshold ?? 1,
      isActive: this.model.isActive ?? true,
    };

    if (!quizData.id || !deepEqual(this.initialPreparedQuiz, quizData)) {
      savedQuiz = quizData.id ? await updateQuiz(quizData, quizData.id) : await createQuiz(quizData);

      this.context.commit('SET_MODEL_VALUE', { key: 'id', value: savedQuiz.id });
    }

    return savedQuiz;
  }

  @Action({ rawError: true })
  private async saveQuestions(quizId: string) {
    const questionEntries = await this.prepareQuestionsData();

    for (const [questionData, questionModel] of Array.from(questionEntries)) {
      if (await this.checkIsDirtyEntity({ entityData: questionData, initialCollection: this.initialPreparedQuestions })) {
        const updatedQuiz = questionData.id
          ? await updateQuestion(questionData, quizId, questionData.id)
          : await createQuestion(questionData, quizId);

        const lastCreatedQuestion = updatedQuiz.questions?.pop?.();

        if (lastCreatedQuestion && !questionModel.id) {
          questionModel.id = lastCreatedQuestion.id;
        }
      }

      if (questionModel.id) {
        await this.saveAnswers(questionModel);
      }
    }
  }

  @Action({ rawError: true })
  private async saveAnswers(question: QuestionModel) {
    const answerEntries = await this.prepareAnswersData(question);

    for (const [answerData, answer] of Array.from(answerEntries)) {
      if (!(await this.checkIsDirtyEntity({ entityData: answerData, initialCollection: this.initialPreparedAnswers }))) {
        continue;
      }

      const updatedQuestion = answer.id
        ? await updateAnswer(answerData, question.id, answer.id)
        : await createAnswer(answerData, question.id);

      const lastCreatedAnswer = updatedQuestion?.answers.pop?.();

      if (lastCreatedAnswer && !answer.id) {
        answer.id = lastCreatedAnswer?.id ?? '';
      }
    }
  }

  @Action({ rawError: true })
  private checkIsDirtyEntity(params: { entityData: FormData; initialCollection: Set<FormData> }): boolean {
    if (!params.entityData.id) {
      return true;
    }

    return !!Array.from(params.initialCollection.values()).find((preparedInitialEntity) => {
      return preparedInitialEntity.id === params.entityData.id && !deepEqual(preparedInitialEntity, params.entityData);
    });
  }
}

export default getModule(QuizEntityModule);

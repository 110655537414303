
import { defineComponent, computed, ref } from 'vue';
import Draggable from 'vuedraggable';

import SettingsModule from '@/store/settings';
import QuizModule from '@/store/settings/quiz';
import QuizEntityModule from '@/store/settings/quiz/entity';

import { GuiTextField, GuiButton, GuiLoader, GuiFormCheckbox } from '@library/gigant_ui';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import AppFieldset from '@/components/ui/Fieldset/Fieldset.vue';
import FormSelect from '@/components/form/Select/index.vue';

import { AnswerModel, QuestionModel } from '@/interfaces/models/quiz.interface';
import { SelectItem } from '@/lib/formFactory/select.interface';

export default defineComponent({
  emits: ['saved', 'cancel'],
  components: {
    GuiFormCheckbox,
    AppFieldset,
    AppRow,
    AppCol,
    FormSelect,
    GuiTextField,
    GuiButton,
    GuiLoader,
    Draggable,
  },
  setup(_, { emit }) {
    const isLoadingCompetenceList = ref(false);
    const filteredCompetences = ref<SelectItem[]>(Array.from(QuizModule.competences));

    const model = computed(() => QuizEntityModule.model);
    const errors = computed(() => QuizEntityModule.errors);
    const isSaving = computed(() => QuizEntityModule.isSaving);
    const isEdit = computed(() => SettingsModule.isEdit);

    const clientItems = computed(() => QuizModule.clients);

    const thresholdItems = computed(() => {
      const questions = QuizEntityModule.model.questions || [{} as QuestionModel];

      return questions.map((_, index) => {
        const id = index + 1;

        return { id, value: `${id} из ${questions.length}` };
      });
    });

    const selectedThreshold = computed<{ id: number; value: string }>({
      get() {
        const defaultOption = thresholdItems.value[0];

        return thresholdItems.value.find((item) => item.id === model.value.threshold) || defaultOption;
      },
      set({ id: value }) {
        QuizEntityModule.model.threshold = value;
      },
    });

    async function save() {
      const quizId = await QuizEntityModule.save();

      if (quizId) {
        emit('saved', quizId);
      }
    }

    function cancel() {
      QuizEntityModule.resetModel();
      SettingsModule.SET_IS_ADD(false);
      SettingsModule.SET_IS_EDIT(false);

      emit('cancel');
    }

    function addQuestion() {
      QuizEntityModule.addNewQuestion();
    }

    function addAnswer(question: QuestionModel) {
      QuizEntityModule.addNewAnswer(question);
    }

    function removeQuestion(question: QuestionModel) {
      QuizEntityModule.removeQuestion(question);
    }

    function removeAnswer(answer: AnswerModel) {
      QuizEntityModule.removeAnswer(answer);
    }

    function endAnswerDrag({ oldIndex, newIndex }: Record<string, number>, question: QuestionModel) {
      QuizEntityModule.replaceAnswer({ question, oldIndex, newIndex });
    }

    function endQuestionDrag({ oldIndex, newIndex }: Record<string, number>) {
      QuizEntityModule.replaceQuestion({ oldIndex, newIndex });
    }

    function updateAnswerText(answer: AnswerModel) {
      const answerError = errors.value.answers.get(answer);

      if (answerError) {
        answerError.text = '';
      }
    }

    function updateQuestionText(question: QuestionModel) {
      const questionError = errors.value.questions.get(question);

      if (questionError) {
        questionError.text = '';
      }
    }

    function updateIsCorrect(question: QuestionModel) {
      const questionError = errors.value.questions.get(question);

      if (questionError) {
        questionError.isCorrectAnswer = false;
      }
    }

    async function updateClientSelect({ id: clientId }: { id: number }) {
      isLoadingCompetenceList.value = true;

      const competences = await QuizModule.fetchClientCompetences(clientId.toString());
      filteredCompetences.value.splice(0, filteredCompetences.value.length);
      filteredCompetences.value.push(...competences);

      const currentCompetenceId = model.value.competence?.id;
      const isCompetenceNotBelongToClient =
        currentCompetenceId && !competences.find((newCompetence) => newCompetence.id === currentCompetenceId);

      if (isCompetenceNotBelongToClient) {
        model.value.competence = { id: '0', value: '' };
      }

      isLoadingCompetenceList.value = false;
      errors.value.client = false;
    }

    return {
      model,
      errors,
      isSaving,
      clientItems,
      filteredCompetences,
      thresholdItems,
      selectedThreshold,
      isEdit,
      isLoadingCompetenceList,
      save,
      cancel,
      addQuestion,
      addAnswer,
      removeAnswer,
      removeQuestion,
      endQuestionDrag,
      endAnswerDrag,
      updateAnswerText,
      updateIsCorrect,
      updateQuestionText,
      updateClientSelect,
    };
  },
});

import FilterFactory from '@/lib/layouts/page/filterFactory';

export default class QuizFilter {
  filterModel: {};

  constructor() {
    const filter = new FilterFactory();

    filter.createSelectEntity('client');
    filter.setTitle('client', 'Клиент');
    filter.setName('client', 'select_client');
    filter.setInitAction('client', 'quiz/initClientsSelect');

    filter.createSelectEntity('competence');
    filter.setTitle('competence', 'Компетенция');
    filter.setName('competence', 'select_competence');
    filter.setInitAction('competence', 'quiz/initCompetencesSelect');

    filter.createSelectEntity('isActive');
    filter.setTitle('isActive', 'Активность');
    filter.setName('isActive', 'select_isActive');
    filter.setAllowZeroId('isActive', true);
    filter.setList('isActive', [
      { id: '1', value: 'Активный' },
      { id: '0', value: 'Неактивный' },
    ]);

    this.filterModel = filter.filterModel;
  }
}

import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a0d350ae"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "cp-margin__t--40 cp-margin__r--8"
}
const _hoisted_2 = ["disabled", "onClick"]
const _hoisted_3 = { class: "cp-margin__t--small-2" }
const _hoisted_4 = { class: "question__answer-list" }
const _hoisted_5 = { class: "question__answer cp-margin__t--small-2" }
const _hoisted_6 = { class: "cp-margin__t-r-10" }
const _hoisted_7 = ["disabled", "onClick"]
const _hoisted_8 = ["onClick"]
const _hoisted_9 = {
  key: 2,
  class: "cp-color-error cp-text__text-primary cp-margin__l--b cp-padding__l-2"
}
const _hoisted_10 = { class: "cp-flex cp-margin__t--small-2 cp-margin__l--sx-2 cp-margin__r--sx cp-margin__b--small-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_select = _resolveComponent("form-select")!
  const _component_app_col = _resolveComponent("app-col")!
  const _component_app_row = _resolveComponent("app-row")!
  const _component_gui_form_checkbox = _resolveComponent("gui-form-checkbox")!
  const _component_app_fieldset = _resolveComponent("app-fieldset")!
  const _component_gui_text_field = _resolveComponent("gui-text-field")!
  const _component_draggable = _resolveComponent("draggable")!
  const _component_gui_loader = _resolveComponent("gui-loader")!
  const _component_gui_button = _resolveComponent("gui-button")!

  return (_ctx.model)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_app_fieldset, {
          withLegend: false,
          class: "cp-margin__b--8"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_app_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_app_col, { class: "cp-padding__r--sm" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_form_select, {
                      class: "cp-form-select__margin cp-width__max--full",
                      label: "Клиент",
                      modelValue: _ctx.model.client,
                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.model.client) = $event)),
                      items: _ctx.clientItems,
                      clear: false,
                      error: _ctx.errors.client,
                      disabled: !!_ctx.model.id || _ctx.isSaving,
                      isSearchEnabled: "",
                      onUpdate: _ctx.updateClientSelect
                    }, null, 8, ["modelValue", "items", "error", "disabled", "onUpdate"])
                  ]),
                  _: 1
                }),
                _createVNode(_component_app_col, { class: "cp-padding__l--sm" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_form_select, {
                      class: "cp-form-select__margin cp-width__max--full",
                      label: "Компетенция",
                      modelValue: _ctx.model.competence,
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.model.competence) = $event)),
                      items: _ctx.filteredCompetences,
                      clear: false,
                      error: _ctx.errors.competence,
                      disabled: !!_ctx.model.id || _ctx.isSaving || _ctx.isLoadingCompetenceList,
                      isSearchEnabled: "",
                      onUpdate: _cache[2] || (_cache[2] = ($event: any) => (_ctx.errors.competence = false))
                    }, null, 8, ["modelValue", "items", "error", "disabled"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_app_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_app_col, { grow: 1 }, {
                  default: _withCtx(() => [
                    (_ctx.thresholdItems)
                      ? (_openBlock(), _createBlock(_component_form_select, {
                          key: 0,
                          class: "cp-form-select__margin cp-width__max--full",
                          label: "Порог",
                          modelValue: _ctx.selectedThreshold,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.selectedThreshold) = $event)),
                          items: _ctx.thresholdItems,
                          disabled: _ctx.isSaving
                        }, null, 8, ["modelValue", "items", "disabled"]))
                      : _createCommentVNode("", true)
                  ]),
                  _: 1
                }),
                _createVNode(_component_app_col, {
                  grow: 4,
                  class: "cp-padding__t-13 cp-padding__l--8"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_gui_form_checkbox, {
                      modelValue: _ctx.model.isActive,
                      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.model.isActive) = $event)),
                      label: "Активен",
                      "true-value": true,
                      "false-value": false,
                      disabled: _ctx.isSaving
                    }, null, 8, ["modelValue", "disabled"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_draggable, {
          "item-key": "index",
          handle: ".question__drag--active",
          "ghost-class": "question--drag-ghost",
          "chosen-class": "question--drag-chosen",
          "drag-class": "question--drag",
          group: "questions",
          modelValue: _ctx.model.questions,
          animation: 200,
          onEnd: _ctx.endQuestionDrag
        }, {
          item: _withCtx(({ index, element: question }) => [
            _createVNode(_component_app_fieldset, { class: "cp-margin__b--8" }, {
              title: _withCtx(() => [
                _createTextVNode("Вопрос " + _toDisplayString(index + 1) + "/" + _toDisplayString(_ctx.model.questions?.length), 1)
              ]),
              default: _withCtx(() => [
                ((_ctx.model.questions?.length ?? 0) > 1)
                  ? (_openBlock(), _createElementBlock("span", {
                      key: 0,
                      class: _normalizeClass(["question__drag icon-drag", { 'question__drag--active': !_ctx.isSaving }])
                    }, null, 2))
                  : _createCommentVNode("", true),
                ((_ctx.model.questions ?? []).length > 1)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 1,
                      class: "question__remove-button",
                      disabled: _ctx.isSaving,
                      onClick: ($event: any) => (_ctx.removeQuestion(question))
                    }, " Удалить ", 8, _hoisted_2))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_gui_text_field, {
                    class: "cp-width--full cp-word-break",
                    label: "Описание вопроса",
                    view: "outlined",
                    modelValue: question.text,
                    "onUpdate:modelValue": ($event: any) => ((question.text) = $event),
                    value: question.text,
                    maxLength: 1024,
                    "scroll-container-height": 72,
                    resizable: false,
                    error: _ctx.errors.questions.get(question)?.text,
                    disabled: _ctx.isSaving,
                    onUpdate: ($event: any) => (_ctx.updateQuestionText(question))
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "value", "error", "disabled", "onUpdate"])
                ]),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_draggable, {
                    "item-key": (item) => item?.id || item?.temporaryId,
                    handle: ".question__answer-drag--active",
                    "ghost-class": "question__answer--drag-ghost",
                    "chosen-class": "question__answer--drag-chosen",
                    "drag-class": "question__answer--drag",
                    group: 'answers' + question.index,
                    modelValue: question.answers,
                    animation: 200,
                    onEnd: ($event: any) => (_ctx.endAnswerDrag($event, question))
                  }, {
                    item: _withCtx(({ element: answer }) => [
                      _createElementVNode("div", _hoisted_5, [
                        _createVNode(_component_app_row, { "align-items": "center" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_app_col, {
                              grow: 0,
                              basic: "auto",
                              width: "25px"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("span", {
                                  class: _normalizeClass(["question__answer-drag icon-drag", { 'question__answer-drag--active': !_ctx.isSaving }])
                                }, null, 2)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_app_col, {
                              grow: 0,
                              basic: "auto",
                              width: "35px"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_gui_form_checkbox, {
                                  class: "cp-margin__t-15",
                                  modelValue: answer.isCorrectAnswer,
                                  "onUpdate:modelValue": ($event: any) => ((answer.isCorrectAnswer) = $event),
                                  "true-value": true,
                                  "false-value": false,
                                  disabled: _ctx.isSaving,
                                  onUpdate: ($event: any) => (_ctx.updateIsCorrect(question))
                                }, null, 8, ["modelValue", "onUpdate:modelValue", "disabled", "onUpdate"])
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_app_col, null, {
                              default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_6, [
                                  _createVNode(_component_gui_text_field, {
                                    class: "cp-width--full cp-word-break",
                                    view: "outlined",
                                    modelValue: answer.text,
                                    "onUpdate:modelValue": ($event: any) => ((answer.text) = $event),
                                    value: answer.text,
                                    maxLength: 1024,
                                    resizable: false,
                                    error: _ctx.errors.answers.get(answer)?.text,
                                    disabled: _ctx.isSaving,
                                    label: " ",
                                    onUpdate: ($event: any) => (_ctx.updateAnswerText(answer))
                                  }, null, 8, ["modelValue", "onUpdate:modelValue", "value", "error", "disabled", "onUpdate"])
                                ])
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_app_col, {
                              grow: 0,
                              basic: "auto",
                              width: "60px",
                              class: "cp-flex cp-flex--justify-end"
                            }, {
                              default: _withCtx(() => [
                                (question.answers.length > 2)
                                  ? (_openBlock(), _createElementBlock("button", {
                                      key: 0,
                                      class: "question__answer-remove-button cp-icon__font-20 icon-trash cp-btn--style-empty cp-padding__t--sm cp-padding__b--14",
                                      disabled: _ctx.isSaving,
                                      onClick: ($event: any) => (_ctx.removeAnswer(answer))
                                    }, null, 8, _hoisted_7))
                                  : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024)
                      ])
                    ]),
                    _: 2
                  }, 1032, ["item-key", "group", "modelValue", "onEnd"])
                ]),
                _createElementVNode("button", {
                  class: "question__answer-add-button icon-plus",
                  onClick: ($event: any) => (_ctx.addAnswer(question))
                }, null, 8, _hoisted_8),
                (_ctx.errors.questions.get(question)?.isCorrectAnswer)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, " Ни один из ответов не является правильным "))
                  : _createCommentVNode("", true)
              ]),
              _: 2
            }, 1024)
          ]),
          _: 1
        }, 8, ["modelValue", "onEnd"]),
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_component_gui_button, {
            class: "cp-margin__r--sx",
            disabled: _ctx.isSaving,
            onBtnClick: _ctx.save
          }, {
            after: _withCtx(() => [
              _createElementVNode("div", {
                class: _normalizeClass(["cp-transition cp-relative", { 'cp-padding__l': _ctx.isSaving }])
              }, [
                (_ctx.isSaving)
                  ? (_openBlock(), _createBlock(_component_gui_loader, {
                      key: 0,
                      inline: "",
                      absoluteCenter: ""
                    }))
                  : _createCommentVNode("", true)
              ], 2)
            ]),
            default: _withCtx(() => [
              _createTextVNode(" Сохранить ")
            ]),
            _: 1
          }, 8, ["disabled", "onBtnClick"]),
          _createVNode(_component_gui_button, {
            class: "cp-margin__r--sx",
            color: "primary",
            label: "Отмена",
            disabled: _ctx.isSaving,
            onBtnClick: _ctx.cancel
          }, null, 8, ["disabled", "onBtnClick"]),
          _createVNode(_component_gui_button, {
            class: "cp-margin__l--auto",
            color: "primary",
            label: "Добавить вопрос",
            disabled: _ctx.isSaving,
            onBtnClick: _ctx.addQuestion
          }, null, 8, ["disabled", "onBtnClick"])
        ])
      ]))
    : _createCommentVNode("", true)
}
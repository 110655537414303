import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import API from '@/config/api-routers';
import { makeDeleteRequest, makeFormRequest, makeGetRequest } from './requests';
import { AssemblerUrl } from '@/lib/UtilsApi';
import { DeleteResponse, FormData } from '@/interfaces/shared';
import { QuestionModel, QuizEntity } from '@/interfaces/models/quiz.interface';

export const getQuizList = async (
  pageCurrent = 1,
  pageAmountItems = '',
  sort = '',
  filter = ''
): Promise<TableApiInterface> => {
  const assemblerUrl = new AssemblerUrl(API.quiz.list);
  const url = assemblerUrl.addPage(pageCurrent).addAmount(pageAmountItems).addSort(sort).addFilter(filter).getUrl();
  const result = await makeGetRequest<{ result: { table: TableApiInterface } }>(url);

  return result.data.result.table;
};

export const getQuizById = async (quizId: string): Promise<QuizEntity> => {
  const result = await makeGetRequest(API.quiz.read(quizId));

  return result.data.result.quiz;
};

export const createQuiz = async (data: FormData): Promise<QuizEntity> => {
  const result = await makeFormRequest(API.quiz.create, data);

  return result.data.result.quiz;
};

export const updateQuiz = async (data: FormData, quizId: string): Promise<QuizEntity> => {
  const result = await makeFormRequest(API.quiz.update(quizId), data);

  return result.data.result.quiz;
};

export const createQuestion = async (data: FormData, quizId: string): Promise<QuizEntity> => {
  const result = await makeFormRequest(API.quiz.questionCreate(quizId), data);

  return result.data.result.quiz;
};

export const updateQuestion = async (data: FormData, quizId: string, questionId: string): Promise<QuizEntity> => {
  const result = await makeFormRequest(API.quiz.questionUpdate(quizId, questionId), data);

  return result.data.result.quiz;
};

export const deleteQuestion = async (quizId: string, questionId: string): Promise<DeleteResponse> => {
  const result = await makeDeleteRequest(API.quiz.questionDelete(quizId, questionId));

  return result.data.result;
};

export const createAnswer = async (data: FormData, questionId: string): Promise<QuestionModel> => {
  const result = await makeFormRequest(API.quiz.answerCreate(questionId), data);

  return result.data.result.quizQuestion;
};

export const updateAnswer = async (data: FormData, questionId: string, answerId: string): Promise<QuestionModel> => {
  const result = await makeFormRequest(API.quiz.answerUpdate(questionId, answerId), data);

  return result.data.result.quizQuestion;
};

export const deleteAnswer = async (questionId: string, answerId: string): Promise<DeleteResponse> => {
  const result = await makeDeleteRequest(API.quiz.answerDelete(questionId, answerId));

  return result.data.result;
};

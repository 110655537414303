import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4d972f65"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cp-admin-title-tab__wrapper" }
const _hoisted_2 = { class: "cp-flex" }
const _hoisted_3 = {
  ref: "filter",
  class: "cp-flex cp-flex--justify-between align-items-end cp-margin__b--small-14"
}
const _hoisted_4 = { class: "cp-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tabs_nav = _resolveComponent("tabs-nav")!
  const _component_app_header_btn_add = _resolveComponent("app-header-btn-add")!
  const _component_form_select = _resolveComponent("form-select")!
  const _component_app_col = _resolveComponent("app-col")!
  const _component_gui_loader = _resolveComponent("gui-loader")!
  const _component_status_active = _resolveComponent("status-active")!
  const _component_app_table = _resolveComponent("app-table")!
  const _component_quiz_form = _resolveComponent("quiz-form")!
  const _component_app_row = _resolveComponent("app-row")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_app_row, {
    wrap: "nowrap",
    direction: "column",
    class: "cp-main-inner__wrapper"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_app_col, {
        grow: 0,
        ref: "header"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_tabs_nav, {
              items: _ctx.tabsNav,
              "active-id": _ctx.currentTab
            }, null, 8, ["items", "active-id"]),
            _createElementVNode("div", _hoisted_2, [
              _withDirectives(_createVNode(_component_app_header_btn_add, { onClick: _ctx.toAdd }, null, 8, ["onClick"]), [
                [_directive_tooltip, 'Создать тест']
              ])
            ])
          ]),
          _createElementVNode("form", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_form_select, {
                class: "cp-width--246 cp-margin__r--24",
                modelValue: _ctx.filterSettings.client.current,
                label: _ctx.filterSettings.client.title,
                items: _ctx.filterSettings.client.list,
                "is-search-enabled": true,
                disabled: _ctx.isLoadingList,
                clear: "",
                filter: "",
                onUpdate: _cache[0] || (_cache[0] = ($event: any) => (_ctx.handleFilterSelect({ type: 'update', changeParams: { key: _ctx.filterSettings.client.key, value: $event } }))),
                onClear: _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleFilterSelect({ type: 'clear', changeParams: _ctx.filterSettings.client.key })))
              }, null, 8, ["modelValue", "label", "items", "disabled"]),
              _createVNode(_component_form_select, {
                class: "cp-width--246 cp-margin__r--24",
                modelValue: _ctx.filterSettings.competence.current,
                label: _ctx.filterSettings.competence.title,
                items: _ctx.filterSettings.competence.list,
                "is-search-enabled": true,
                disabled: _ctx.isLoadingList,
                clear: "",
                filter: "",
                onUpdate: _cache[2] || (_cache[2] = ($event: any) => (
              _ctx.handleFilterSelect({ type: 'update', changeParams: { key: _ctx.filterSettings.competence.key, value: $event } })
            )),
                onClear: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleFilterSelect({ type: 'clear', changeParams: _ctx.filterSettings.competence.key })))
              }, null, 8, ["modelValue", "label", "items", "disabled"]),
              _createVNode(_component_form_select, {
                class: "cp-width--246",
                modelValue: _ctx.filterSettings.isActive.current,
                label: _ctx.filterSettings.isActive.title,
                items: _ctx.filterSettings.isActive.list,
                disabled: _ctx.isLoadingList,
                clear: "",
                filter: "",
                onUpdate: _cache[4] || (_cache[4] = ($event: any) => (
              _ctx.handleFilterSelect({ type: 'update', changeParams: { key: _ctx.filterSettings.isActive.key, value: $event } })
            )),
                onClear: _cache[5] || (_cache[5] = ($event: any) => (_ctx.handleFilterSelect({ type: 'clear', changeParams: _ctx.filterSettings.isActive.key })))
              }, null, 8, ["modelValue", "label", "items", "disabled"])
            ])
          ], 512)
        ]),
        _: 1
      }, 512),
      (_ctx.isLoadingList && !_ctx.isInitiatedTable)
        ? (_openBlock(), _createBlock(_component_app_col, {
            key: 0,
            grow: 1
          }, {
            default: _withCtx(() => [
              _createVNode(_component_gui_loader, { absoluteCenter: "" })
            ]),
            _: 1
          }))
        : (_openBlock(), _createBlock(_component_app_col, {
            key: 1,
            grow: 1
          }, {
            default: _withCtx(() => [
              _createVNode(_component_app_row, { class: "block-radius" }, {
                default: _withCtx(() => [
                  _createVNode(_component_app_col, { class: "cp-flex" }, {
                    default: _withCtx(() => [
                      (_ctx.isInitiatedTable)
                        ? (_openBlock(), _createBlock(_component_app_table, {
                            key: 0,
                            ref: "table",
                            class: _normalizeClass(["quiz-table app-table--row-pointer grow-1", {
              'quiz-table--sticky': !_ctx.isHeaderInView,
            }]),
                            headers: _ctx.settings.table.titles,
                            items: _ctx.settings.table.rows,
                            "default-footer": true,
                            page: _ctx.settings.table.pagination.currentPage,
                            "total-pages": _ctx.settings.table.pagination.totalPages,
                            "total-items": _ctx.settings.table.totalItems,
                            "page-items": _ctx.settings.pageAmountItems && +_ctx.settings.pageAmountItems,
                            "is-hover": true,
                            isLoading: _ctx.isLoadingList,
                            onClickRow: _cache[6] || (_cache[6] = ($event: any) => (_ctx.toEdit($event.item.id))),
                            onChangePage: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$store.dispatch(_ctx.settings.actionPagination as string, $event))),
                            onSelectAmount: _ctx.selectAmount,
                            onSort: _ctx.sort
                          }, {
                            "item-threshold": _withCtx(({ value, item }) => [
                              _createTextVNode(_toDisplayString(value) + "/" + _toDisplayString(item.questionCount), 1)
                            ]),
                            "item-isActive": _withCtx(({ value }) => [
                              _createVNode(_component_status_active, { "is-active": value }, null, 8, ["is-active"])
                            ]),
                            _: 1
                          }, 8, ["class", "headers", "items", "page", "total-pages", "total-items", "page-items", "isLoading", "onSelectAmount", "onSort"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_app_col, null, {
                    default: _withCtx(() => [
                      (_ctx.isLoadingEntity)
                        ? (_openBlock(), _createBlock(_component_gui_loader, {
                            key: 0,
                            absoluteCenter: ""
                          }))
                        : (_ctx.isAdd || _ctx.isEdit)
                          ? (_openBlock(), _createBlock(_component_quiz_form, {
                              key: 1,
                              onSaved: _ctx.handleSaved,
                              onCancel: _cache[8] || (_cache[8] = ($event: any) => (_ctx.activeRow = null))
                            }, null, 8, ["onSaved"]))
                          : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }))
    ]),
    _: 1
  }))
}
import { Action, Module, Mutation, getModule } from 'vuex-module-decorators';
import store from '@/store';
import PageBaseModule from '@/store/page';

import QuizEntityModule from './entity';
import SettingsModule from '@/store/settings';
import filterModel from '@/store/settings/quiz/filter';
import QuizFilter from '@/store/settings/quiz/filterEntity';
import { getAllCompetenceList, getCompetenceList } from '@/api/competence';
import { getQuizList } from '@/api/quiz';
import { getClients } from '@/api/client';
import { UITable } from '@/lib/util/tableUtils';
import PageEntity from '@/lib/layouts/page/pageEntity';
import { Filter } from '@/lib/layouts/page/filter.interface';
import { SelectItem } from '@/lib/formFactory/select.interface';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';

export const MODULE_NAME = 'quiz';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class QuizModule extends PageBaseModule {
  isLoading = true;
  filter: Filter;
  clients: SelectItem[] = [];
  competences: SelectItem[] = [];
  clientCompetences = new Map<string, SelectItem[]>();

  constructor(module: QuizModule) {
    super(module);

    const page = new PageEntity();
    page.values.actionPagination = 'quiz/updatePage';
    this.pageSettings = page.values;

    const filter = new filterModel();
    this.filter = getModule(QuizFilter);
    this.filter.setFilterName('quizFilter');
    this.filter.setFilterModel(filter.filterModel);
  }

  @Mutation
  UPDATE_CLIENTS(value: SelectItem[]) {
    this.clients = value;
  }

  @Mutation
  UPDATE_COMPETENCES(value: SelectItem[]) {
    this.competences = value;
  }

  @Mutation
  UPDATE_IS_LOADING(value: boolean) {
    this.isLoading = value;
  }

  @Mutation
  UPDATE_CLIENT_COMPETENCES(params: { clientId: string; competences: SelectItem[] }) {
    this.clientCompetences.set(params.clientId, params.competences);
  }

  @Action({ rawError: true })
  setList(table: TableApiInterface) {
    const uiTable = new UITable(table);
    const sorts = Object.values(this.pageSettings.sort);
    const tableInfo = uiTable
      .removeColumn('id')
      .removeColumn('questionCount')
      .setProperty('threshold', 'align', 'right')
      .setProperty('isActive', 'align', 'center')
      .changeTitleOrder('client', 0)
      .changeTitleOrder('competence', 1)
      .setSortableValues(sorts)
      .getTable();

    this.context.commit('SET_TABLE', tableInfo);
  }

  @Action({ rawError: true })
  async updateIsAdd(isAdd: boolean) {
    QuizEntityModule.resetModel();
    SettingsModule.SET_IS_EDIT(false);
    QuizEntityModule.SET_ID('');
    SettingsModule.SET_IS_ADD(isAdd);
    QuizEntityModule.addNewQuestion();
    await QuizEntityModule.prepareInitialModels();
  }

  @Action({ rawError: true })
  async updateIsEdit(params: { isEdit: boolean; id: string }) {
    QuizEntityModule.resetModel();
    QuizEntityModule.SET_ID(params.id);
    SettingsModule.SET_IS_ADD(false);
    SettingsModule.SET_IS_EDIT(params.isEdit);

    if (params.id) {
      await QuizEntityModule.getQuizById(params.id);
    }
  }

  @Action({ rawError: true })
  async init() {
    this.pageSettings.pageAmountItems = await this.context.dispatch('getPageAmountStorageValue', MODULE_NAME);

    await this.filter.init();

    const filterClientId = this.filter.filterSettings.filterModel.client?.current?.id?.toString();
    if (filterClientId) {
      await this.updateCompetenceSelectList(filterClientId);
    }

    await this.getList();
  }

  @Action({ rawError: true })
  async updatePageAmountItems(number: string) {
    await this.context.commit('SET_PAGE', 1);
    await this.context.dispatch('updatePaginationSettings', {
      moduleName: MODULE_NAME,
      amountItems: number,
    });

    await this.getList();
  }

  @Action({ rawError: true })
  async initClientsSelect() {
    let clients: SelectItem[] = [];

    try {
      const result = await getClients();

      clients = result.clients?.map((client) => ({ id: client.id, value: client.name }));

      this.context.commit('UPDATE_CLIENTS', clients);
    } catch (error) {
      console.error(error);
    }

    return clients;
  }

  @Action({ rawError: true })
  async initCompetencesSelect() {
    let competences: SelectItem[] = [];

    try {
      const competenceList = await getAllCompetenceList();

      competences = competenceList.map((competence: { id: string; name: string }) => ({
        id: competence.id,
        value: competence.name,
      }));

      this.context.commit('UPDATE_COMPETENCES', competences);
    } catch (error) {
      console.error(error);
    }

    return competences;
  }

  @Action({ rawError: true })
  async changeFilterSelect<T extends { key: string; value: { id: string; value: string } }>({
    type,
    changeParams,
  }: {
    type: string;
    changeParams: T | string;
  }) {
    if ((changeParams as T)?.key === 'client') {
      await this.updateCompetenceSelectList((changeParams as T).value?.id);
    }

    if (type === 'update') {
      this.filter.updateSelect(changeParams as T);
    } else if (type === 'clear') {
      this.filter.resetSelect(changeParams as string);
    }

    await this.SET_PAGE(1);
    await this.filter.updateFilter();
    await this.getList();
  }

  @Action({ rawError: true })
  async updateCompetenceSelectList(clientId?: string): Promise<SelectItem[]> {
    let competences: SelectItem[] = [];

    if (!clientId) {
      competences = this.competences;
    } else if (this.clientCompetences.has(clientId)) {
      competences = this.clientCompetences.get(clientId) ?? [];
    } else {
      competences = await this.fetchClientCompetences(clientId);
    }

    this.filter.updateSelectList({ key: 'competence', list: competences });

    const selectedCompetence = (this.filter.filterSettings.filterModel.competence as { current: { id: string } })?.current
      .id;

    const isNotExistsSelectedCompetence = !competences.find((competence) => competence.id.toString() === selectedCompetence);

    if (isNotExistsSelectedCompetence) {
      this.filter.resetSelect('competence');
    }

    return competences;
  }

  @Action({ rawError: true })
  async fetchClientCompetences(clientId: string) {
    let competences: SelectItem[] = [];

    try {
      const competenceTable = await getCompetenceList(
        1,
        'items=1000',
        undefined,
        `&filters[1][id]=active&filters[1][value]=1&filters[2][id]=client&filters[2][value]=${clientId}`
      );

      competences = competenceTable.rows.map((competence) => ({
        id: competence.id.toString(),
        value: competence.name,
      }));

      this.UPDATE_CLIENT_COMPETENCES({ clientId, competences });
    } catch (error) {
      console.error(error);
    }

    return competences;
  }

  @Action({ rawError: true })
  async getList() {
    try {
      this.context.commit('UPDATE_IS_LOADING', true);

      const itemsQuery = await this.context.dispatch('getItemsQuery', MODULE_NAME);
      const sort = await this.getSortForRequest();
      const filter = this.filter.filterSettings.filter;
      const result = await getQuizList(this.pageSettings.pageCurrent, itemsQuery, sort, filter);

      await this.context.dispatch('setList', result);
    } catch (error) {
      this.context.commit('setGlobalError', false);
    } finally {
      this.context.commit('UPDATE_IS_LOADING', false);
    }
  }
}

export default getModule(QuizModule);

import { ComponentPublicInstance, Ref, watch, unref, getCurrentScope, onScopeDispose } from 'vue';

type VueInstance = ComponentPublicInstance;
type MaybeRef<T> = T | Ref<T>;
type MaybeElement = HTMLElement | SVGElement | VueInstance | undefined | null;
type MaybeElementRef<T extends MaybeElement = MaybeElement> = MaybeRef<T>;
type Fn = () => void;

export interface IntersectionObserverOptions {
  root?: MaybeElementRef;
  rootMargin?: string;
  threshold?: number | number[];
}

function unrefElement(elRef: MaybeElementRef): HTMLElement | SVGElement | undefined {
  const plain = unref(elRef);

  return (plain as VueInstance)?.$el ?? plain;
}

function tryOnScopeDispose(fn: Fn) {
  if (getCurrentScope()) {
    onScopeDispose(fn);

    return true;
  }

  return false;
}

export default function useIntersectionObserver(
  target: Ref,
  callback: IntersectionObserverCallback,
  options: IntersectionObserverOptions = {}
): { isSupported: boolean; stop: () => void } {
  const { root, rootMargin = '0px', threshold = 0.1 } = options;

  /* eslint-disable @typescript-eslint/no-empty-function */
  const noop = () => {};

  let cleanup = noop;
  const isSupported = window && 'IntersectionObserver' in window;

  const stopWatch = isSupported
    ? watch(
        () => ({
          el: unrefElement(target),
          root: unrefElement(root),
        }),
        (elems) => {
          cleanup();

          if (!elems.el) {
            return;
          }

          const observer = new IntersectionObserver(callback, {
            root: elems.root,
            rootMargin,
            threshold,
          });
          observer.observe(elems.el);

          cleanup = () => {
            observer.disconnect();
            cleanup = noop;
          };
        },
        { immediate: true, flush: 'post' }
      )
    : noop;

  const stop = () => {
    cleanup();
    stopWatch();
  };

  tryOnScopeDispose(stop);

  return {
    isSupported,
    stop,
  };
}
